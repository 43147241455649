import React from 'react'
import './footer.css'
import {NavLink} from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faLocation, faPhone, faEnvelope} from '@fortawesome/free-solid-svg-icons'
import { faFacebook, faInstagramSquare, faLinkedin, faYoutubeSquare, faTwitterSquare } from '@fortawesome/free-brands-svg-icons'

const Footer = () => {
  return (
    <>
      <section class="footersection">
	<div class="container">
		<div class="row">
			<div class="col-md-5">
				<h3>For more information</h3>
				<div class="footline"></div>
				<p>We are eagerly waiting for your suggestions.<br/>Kindly follow us for more support.</p>
			</div>
            <div className='col-md-2'></div>
			<div class=" footer2 col-md-5 text-center">
				<h3>Quick Links</h3>
				<div class="footline"></div>
				<li><NavLink className="nav-link" to="/">Home</NavLink></li>
				<li><NavLink className="nav-link" to="/">Services</NavLink></li>
			</div>
			{/* <div class=" footer3 col-md-4">
				<h3>Contact Us</h3>
				<div class="footline"></div>
				<li><FontAwesomeIcon icon={faLocation}></FontAwesomeIcon> Nashik, Maharashtra</li>
				<li><FontAwesomeIcon icon={faPhone}></FontAwesomeIcon> +91-9860363068</li>
				<li><FontAwesomeIcon icon={faEnvelope}></FontAwesomeIcon> contact@dhindoraa.com</li>
			</div> */}
		</div>
		<div class="row">
			<div class="col-lg-12 col-md-12 col-12 social-div text-center mt-3 line-spacing-1">
                <a href="https://twitter.com/earthid_ssi" target="_blank"><FontAwesomeIcon icon={faTwitterSquare}/></a>
				<a href="https://www.youtube.com/channel/UCaRjLyN4I_0UrK3NL4zU1Vg/featured" target="_blank"><FontAwesomeIcon icon={faYoutubeSquare}/></a>
				<a href="https://www.facebook.com/myearth.id/" target="_blank"><FontAwesomeIcon icon={faFacebook}/></a>
				<a href="https://www.instagram.com/myearthid/" target="_blank"><FontAwesomeIcon icon={faInstagramSquare}/></a>
				<a href="https://www.linkedin.com/company/myearthid/" target="_blank"><FontAwesomeIcon icon={faLinkedin}/></a>
			</div>
		</div>
		<hr/>
		<div class="mt-10 text-center ">
			<p>© 2023 EarthID, Inc. All Rights Reserved.</p>
		</div>
	</div>
</section>

    </>
  )
}

export default Footer
