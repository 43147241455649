
import axios from "axios";
import DocumentMetadata from "./components/UploadDoc/UploadPdf";
import openSocket from "socket.io-client";
import crypto, { createHmac } from "crypto";


const ssiBaseUrl = "http://15.207.193.210:8090";
const authorizationKey = "01a41742-aa8e-4dd6-8c71-d577ac7d463c";

const privateKey = "0OO7zP0StbaiJ+Z3+rWo9nMKTaGYdtoiY4lflmyeU6BN++mp89qruedrv/vKPxnAOx9AfLQnP9MEK9AVA0/lOQ==";
const publicKey = "TfvpqfPaq7nna7/7yj8ZwDsfQHy0Jz/TBCvQFQNP5Tk=";
const issuerDID = "did:earthid:testnet:7yymkciEdteEJbBFj18S2ZogbZxhFsu5wCHoJXH4Dpw2;earthid:testnet:fid=0.0.4438836";
const userDID = "did:earthid:testnet:9owdudLM9EAz9TBcdgGEYVk4o7nN2dgN3uz6WgciQbzA;earthid:testnet:fid=0.0.4438836";



const ssiApi = {


  //SSI Api from here

      async createUserDid() {
        var config = {
          method: "get",
          url: ssiBaseUrl + "/api/user/did",
          headers: {
            "X-API-KEY": authorizationKey,
            "publicKey": publicKey
          },
          data: "",
        };
      
        return new Promise((resolve, reject) => {
          try {
            axios(config)
              .then(function (response) {
                console.log(response.data); // Log the response data
                resolve(JSON.stringify(response.data.data));
              })
              .catch(function (error) {
                console.log(error);
              });
          } catch (e) {
            console.log("Error while getJourneyResult: ", e);
            reject(e);
          }
        });
      },


      async createUserSignature() {
        try {
          //const issuerDID = await this.createIssuerDid();
          const userDID = await this.createUserDid();
          const allMetaData = JSON.parse(localStorage.getItem('pdfMetaData'));
        console.log(allMetaData[0])
          const payload = { "payload": {
            credentialSubject: {
                "id": "did:earthid:testnet:9owdudLM9EAz9TBcdgGEYVk4o7nN2dgN3uz6WgciQbzA;earthid:testnet:fid=0.0.4438836",
                ...allMetaData
              }
          }
           
          };
      
          const config = {
            method: 'post',
            url: ssiBaseUrl + `/api/user/sign?issuerDID=${issuerDID}`,
            headers: {
              'X-API-KEY': authorizationKey,
              'privateKey': privateKey,
              'Content-Type': 'application/json'
            },
            data: JSON.stringify(payload)
          };
      
          const response = await axios.request(config);
          console.log(JSON.stringify(response.data));
          return response.data.Signature;
        } catch (error) {
          console.log(error);
          throw error;
        }
      },

      async createVC() {
        try {

            const userDID = await this.createUserDid();
            const signature = await this.createUserSignature();
            const allMetaData = JSON.parse(localStorage.getItem('pdfMetaData'));
          console.log(userDID, allMetaData[0], signature)

          const data = {
            "schemaName": "AuditDocumentPdf:6",
            "isEncrypted": false,
            "dependantVerifiableCredential": [],
            "credentialSubject": {
                "allMetaData": [allMetaData]
            }
          };
      console.log(data.credentialSubject)
          const config = {
            method: 'post',
            url: ssiBaseUrl + "/api/issuer/verifiableCredential?isCryptograph=false&downloadCryptograph=false",
            headers: {
              'X-API-KEY': authorizationKey,
              'did': userDID,
              'publicKey': publicKey,
              'userSignature': signature, // Call the createUserSignature function
              'Content-Type': 'application/json'
            },
            data: JSON.stringify(data)
          };
      
          const response = await axios.request(config);
          console.log(JSON.stringify(response.data));


          // Retrieve existing stored VCs or initialize with an empty array
    let storedVCs = this.getStoredVC();

    if (!Array.isArray(storedVCs)) {
      storedVCs = []; // Initialize as an empty array if it's not an array
    }

    // Append the new VC to the array
    storedVCs.push(response.data.data.verifiableCredential);

    // Store the updated array in localStorage
    localStorage.setItem('verifiableCredential', JSON.stringify(storedVCs));


          return response.data;
        } catch (error) {
          console.log(error);
          throw error;
        }
      },

      getStoredVC() {
        const vcString = localStorage.getItem("verifiableCredential");
  console.log(vcString);
  if (vcString) {
    return JSON.parse(vcString) || []; // Parse the value or return an empty array
  }
  return [];
      },

      async verifyVC() {
        try {

            const userDID = await this.createUserDid();
            const signature = await this.createUserSignature();
            const allVCs = JSON.parse(localStorage.getItem('verifiableCredential'));
          console.log(userDID, allVCs[allVCs.length - 1], signature)

          const data = { "credentials": {
            ...allVCs[allVCs.length - 1]
        }
        }
          const config = {
            method: 'post',
            url: ssiBaseUrl + "/api/verifier/verify?zpkValidation=false",
            headers: {
              'X-API-KEY': authorizationKey,
              'publicKey': publicKey,
              'userSignature': signature, // Call the createUserSignature function
              'Content-Type': 'application/json'
            },
            data: JSON.stringify(data)
          };
      
          const response = await axios.request(config);
          console.log(JSON.stringify(response.data));

          return response.data;
        } catch (error) {
          console.log(error);
          throw error;
        }
      },

}

export default ssiApi
