import React from 'react';
import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  page: {
    flexDirection: 'row',
    backgroundColor: '#E4E4E4'
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1
  }
});

const CertificatePDF = ({ userName, expirationDate }) => (
  <Document>
    <Page size="A4" style={styles.page}>
      <View style={styles.section}>
        <Text>On-Boarding Certificate</Text>
        <Text>Year 2023-2024</Text>
        <Text>This certificate verifies that</Text>
        <Text>{userName}</Text>
        <Text>has successfully gone through the document verification and</Text>
        <Text>has completed all the on-boarding processes of the university.</Text>
        <Text>This certificate is a verifiable credential and</Text>
        <Text>is valid till: {expirationDate}.</Text>
        <Text>EarthID University</Text>
      </View>
    </Page>
  </Document>
);

export default CertificatePDF;
