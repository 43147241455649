import React, { Component } from "react";
import crypto from "crypto";
import data from "./api";
import { store } from "react-notifications-component";
import "./App.css";


const {
  listenForUserData,
  listenForServiceProviderResponse,
  generateQrCodeNest,
  registerVendor,
} = data;

let showNotification = true;

class Passwordless extends Component {
  state = {
    showSpinner: false,
    showBtn: true,
    requestNo: "",
    qrcode: false,
    response: false,
    btnText: "Login with EarthID",
    showText: true,
    showModal: true,
    modalHeading: false,
  };


  userDenied = () => {
    this.setState({
        response: false,
        showModal: false,
    });
    setTimeout(() => {
        document.getElementById("exampleModalLogin").click();
        if (showNotification === true) {
            showNotification = false;
            store.addNotification({
                title: "Error!",
                message: `User Didn't Authorize!`,
                type: "danger",
                insert: "top",
                container: "top-right",
                animationIn: ["animated", "fadeIn"],
                animationOut: ["animated", "fadeOut"],
                dismiss: {
                    duration: 3000,
                    onScreen: true,
                },
            });
        }
    }, 0);
};


updateNotificationState = () => {
    setTimeout(() => {
        showNotification = true;
    }, 100);
    this.redirectToGenerateCred();
};



listenForSocket = () => {
    listenForServiceProviderResponse((err, res) => {
      // console.log("nikhil", res);
        this.setState({
            qrcode: false,
            response: false,
            showSpinner: true,
            showText: false,
        });
    });

  listenForUserData(async (err, res) => {
        let bytes = res.userDetails || res.newreq;
        
        this.setState({
            showQr: false,
            showSpinner: false,
        });
        let creditnals;
        let sessionKey;
        if (bytes === "denied" || bytes.permission === "denied") {
            this.userDenied();
        } else if(
            bytes.permission === "granted" ||
            bytes === "issuerLogin" ||
            bytes === "credentialsgenerated" ||
            bytes === "verifylogin" ||
            bytes === "success"
        ) {
            this.setState({
                response: true,
                showSpinner: false,
                loggedIn: true,
                showModal: false,
                btnText: "LogOut",
                bytes: "issuerLogin",
            });
            setTimeout(() => {
                document.getElementById("exampleModalLogin").click();
                let popMessage;
                if (showNotification === true) {
                    if (this.state.bytes === "credentialsgenerated") {
                        popMessage = "Credentials Generated";
                    } else {
                        popMessage = `Login Successful`;
                    }
                    showNotification = false;
                    store.addNotification({
                        title: "Success!",
                        message: popMessage,
                        type: "success",
                        insert: "top",
                        container: "top-right",
                        animationIn: ["animated", "fadeIn"],
                        animationOut: ["animated", "fadeOut"],
                        dismiss: {
                            duration: 3000,
                            onScreen: true,
                        },
                    });
                    if (bytes.permission === "granted") {
                        this.updateNotificationState();
                    }
                }
            }, 0);
        }  else {
            this.setState({
                response: false,
                showModal: false,
            });
            setTimeout(() => {
                document.getElementById("exampleModalLogin").click();
                if (showNotification === true) {
                    showNotification = false;
                    store.addNotification({
                        title: "Error!",
                        message: `User Didn't Authorize!`,
                        type: "danger",
                        insert: "top",
                        container: "top-right",
                        animationIn: ["animated", "fadeIn"],
                        animationOut: ["animated", "fadeOut"],
                        dismiss: {
                            duration: 3000,
                            onScreen: true,
                        },
                    });
                }
            }, 0);
        }
    });
};



generateQrCodeData = async () => {

    registerVendor()
        .then((d) => {
            const timestamp = Date.now();
            const hash = crypto
                .createHmac("sha256", d.secretKey)
                .update(JSON.stringify(d.apiKey + "Rejolut@1008" + timestamp))
                .digest("hex");
            generateQrCodeNest(hash, d.apiKey, "Rejolut@1008", timestamp, "login")
                .then((data) => {
                    this.listenForSocket();
                    this.setState({
                        showQr: true,
                        qrCodeData: data,
                        apiKey: this.state.apikey,
                        qrcode: false,
                    });
                })
                .catch((err) => {
                    alert(err);
                });
        })
        .catch((err) => {
            alert(err);
        });
};

//Admin functions


adminNotificationState = () => {
  setTimeout(() => {
      showNotification = true;
  }, 100);
  this.redirectToAdmin();
};


redirectToAdmin() {
  window.location.href = "/admin";
}

listenForSocketAdmin = () => {
  listenForServiceProviderResponse((err, res) => {
    // console.log("nikhil", res);
      this.setState({
          qrcode: false,
          response: false,
          showSpinner: true,
          showText: false,
      });
  });

listenForUserData(async (err, res) => {
      let bytes = res.userDetails || res.newreq;
      console.log("bytes", bytes);
      this.setState({
          showQr: false,
          showSpinner: false,
      });
      let creditnals;
      let sessionKey;
      if (bytes === "denied" || bytes.permission === "denied") {
          this.userDenied();
      } else if (
          bytes.permission === "granted" ||
          bytes === "issuerLogin" ||
          bytes === "credentialsgenerated" ||
          bytes === "verifylogin" ||
          bytes === "success"
      ) {
          this.setState({
              response: true,
              showSpinner: false,
              loggedIn: true,
              showModal: false,
              btnText: "LogOut",
              bytes: "issuerLogin",
          });
          setTimeout(() => {
              document.getElementById("exampleModalLogin").click();
              let popMessage;
              if (showNotification === true) {
                  if (this.state.bytes === "credentialsgenerated") {
                      popMessage = "Credentials Generated";
                  } else {
                      popMessage = `Login Successful`;
                  }
                  showNotification = false;
                  store.addNotification({
                      title: "Success!",
                      message: popMessage,
                      type: "success",
                      insert: "top",
                      container: "top-right",
                      animationIn: ["animated", "fadeIn"],
                      animationOut: ["animated", "fadeOut"],
                      dismiss: {
                          duration: 3000,
                          onScreen: true,
                      },
                  });
                  if (bytes.permission === "granted") {
                      this.adminNotificationState();
                  } else if (bytes === "verifylogin") {
                      this.verifyNotificationState();
                  }
              }
          }, 0);
      } else {
          this.setState({
              response: false,
              showModal: false,
          });
          setTimeout(() => {
              document.getElementById("exampleModalLogin").click();
              if (showNotification === true) {
                  showNotification = false;
                  store.addNotification({
                      title: "Error!",
                      message: `User Didn't Authorize!`,
                      type: "danger",
                      insert: "top",
                      container: "top-right",
                      animationIn: ["animated", "fadeIn"],
                      animationOut: ["animated", "fadeOut"],
                      dismiss: {
                          duration: 3000,
                          onScreen: true,
                      },
                  });
              }
          }, 0);
      }
  });
};

generateQrCodeDataAdmin = async () => {

  registerVendor()
      .then((d) => {
          const timestamp = Date.now();
          const hash = crypto
              .createHmac("sha256", d.secretKey)
              .update(JSON.stringify(d.apiKey + "Rejolut@1008" + timestamp))
              .digest("hex");
          generateQrCodeNest(hash, d.apiKey, "Rejolut@1008", timestamp, "login")
              .then((data) => {
                  this.listenForSocketAdmin();
                  this.setState({
                      showQr: true,
                      qrCodeData: data,
                      apiKey: this.state.apikey,
                      qrcode: false,
                  });
              })
              .catch((err) => {
                  alert(err);
              });
      })
      .catch((err) => {
          alert(err);
      });
};

//Admin Functions end

qrcode = () => {
    let { qrCodeData } = this.state;
    return (
        <div className="qr-container">
            <img src={qrCodeData} alt="new" />
        </div>
    );
};

redirectToHome() {
    window.location.href = "/";
}
redirectToGenerateCred() {
    window.location.href = "/uploadDoc";
}




  render() {
    let showButton = this.state.showSpinner ? (
      <div className="spinner-div">
        <img className="spinner" src={require("./spinner.gif")} />
      </div>
    ) : null;
    return (
      


<>

{window.location.pathname == "/" ? (
                <>
                  <button
                    type="button"
                    className="nav-buttons"
                    data-toggle="modal"
                    data-target="#exampleModalLogin"
                    onClick={this.state.btnText=="LogOut"?this.redirectToHome:this.generateQrCodeData}
                    style={{ marginRight: "2rem", marginLeft: "2rem" }}
                  >
                    Student Login
                  </button>
                  <button
                    type="button"
                    className="nav-buttons"
                    data-toggle="modal"
                    data-target="#exampleModalLogin"
                    onClick={this.state.btnText=="LogOut"?this.redirectToHome:this.generateQrCodeDataAdmin}
                    style={{ marginRight: "2rem", marginLeft: "2rem" }}
                  >
                    Admin Login
                  </button>
                  </>
              ) : (
                <>
                  <button
                    type="button"
                    className="nav-buttons"
                    data-toggle="modal"
                    data-target="#exampleModalLogin"
                    onClick={this.redirectToHome}
                    style={{ marginRight: "2rem", marginLeft: "2rem" }}
                  >
                    Logout
                  </button>
                  
                </>
              )}


          <div className="modal_container" id="modal-container">
            <div className="modal">
              <div className="qr-code">
                <img
                  src='https://api.qrserver.com/v1/create-qr-code/?data={"apikey":"UKURGTREFATPSYJ","reqNo":"BAZQUVRDTLF","sessionKey":"BAZQUVRDTLF","requestType":"login","encryptionkey":"loginDetailsEncryption" }'
                  alt="QR-Code"
                />
              </div>

              <button id="btn-close">Close me</button>
            </div>
          </div>

          <div className="hero-section">
          <div className="shoppingCart"></div>
          <div
            className="modal fade"
            id="exampleModalLogin"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog" role="document">
              {
                <div>
                  {this.state.showModal ? (
                    <div className="modal-content">
                      <div className="modal-header">
                        <h4 style={{ color: "black" }}>
                          {this.state.modalHeading && !this.state.showSpinner
                            ? "Use Earth ID app to scan the QR code"
                            : "Waiting for user authorization"}
                        </h4>
                      </div>
                      <div className="modal-body">
                        <div className="pop-upbox">
                          {showButton}
                          {this.state.response
                            ? null
                            : this.state.qrcode
                              ? this.generateQrCodeData()
                              : null}
                          {this.state.showQr ? (
                            this.qrcode()
                          ) : this.state.showSpinner ? (
                            <img
                              className="spinner"
                              src={require("./spinner.gif")}
                            />
                          ) : null}
                        </div>
                      </div>
                      <div className="modal-footer">
                        <button
                          type="button"
                          className="btn btn-secondary p-btn"
                          data-dismiss="modal"
                        >
                          Close
                        </button>
                      </div>
                    </div>
                  ) : null}
                </div>
              }
            </div>
          </div>
        </div>
     
      
        </>
      
     
    );
  }
}

export default Passwordless;
