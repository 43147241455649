import React, { Component } from "react";
import openSocket from "socket.io-client";
import CryptoJS from "react-native-crypto-js";
import crypto, { createHmac } from "crypto";
import data from "./api";
import { store } from "react-notifications-component";
import "./App.css";
import Swal from "sweetalert2";
import clipboardCopy from 'clipboard-copy';


import Baseui from "./components/BaseUI/baseui";

// export const isTestnet = true;
// const socketURL =
//     isTestnet === true
//         ? "https://socket-stagev2.myearth.id"
//         : "https://socketv2.myearth.id";
// const socket = openSocket(socketURL, {
//     transports: ["websocket", "polling", "flashsocket"],
// });
// let socketId;
// socket.on("connect", () => {
//     socketId = socket.id;
//     //console.log(`I'm connected with the back-end`);
// });

const {
    listenForUserData,
    listenForServiceProviderResponse,
    getApiKey,
    generateQrCodeNest,
    getUserCreditnals,
    registerVendor,
} = data;

let showNotification = true;

class CreateCred extends Component {
    state = {
        showSpinner: false,
        showBtn: true,
        requestNo: "",
        qrcode: false,
        response: false,
        email: "",
        mno: "",
        profileDrop: false,
        btnText: "Login with Earth Id",
        showText: true,
        showModal: true,
        modalHeading: false,
        createDid: "",
        creditnals: "",
    };

    loader = () => {
        this.setState({ showBtn: false, modalHeading: true });
        this.setState({ qrcode: true, showSpinner: false });
        if (!this.state.showModal) {
            this.setState({
                showModal: true,
            });
        }
        if (this.state.loggedIn) {
            localStorage.removeItem("email");
            localStorage.removeItem("mobile_no");
            localStorage.removeItem("user_name");
            localStorage.removeItem("dob");
            localStorage.removeItem("e_verified");
            localStorage.removeItem("trust_score");
            localStorage.removeItem("earth_id");
            localStorage.removeItem("m_verified");
            this.setState({
                loggedIn: false,
                btnText: "Login with Earth Id",
                qrcode: true,
                response: false,
                showModal: false,
            });
            setTimeout(() => {
                document.getElementById("exampleModalLogin").click();
            }, 0);
            this.redirectToHome();
        }
    };

    componentDidMount = async () => {
        if (localStorage.email != null) {
            this.setState({
                loggedIn: true,
                btnText: "LogOut",
            });
        }
        if (!this.state.apiKey) {
            this.state.apiKey = getApiKey();
        }
    };

    updateNotificationState = () => {
        setTimeout(() => {
            showNotification = true;
        }, 100);
        this.redirectToGenerateCred();
    };

    verifyNotificationState = () => {
        setTimeout(() => {
            showNotification = true;
        }, 100);
        this.redirectToVerifyCreditnals();
    };

    userDenied = () => {
        this.setState({
            response: false,
            showModal: false,
        });
        setTimeout(() => {
            document.getElementById("exampleModalLogin").click();
            if (showNotification === true) {
                showNotification = false;
                store.addNotification({
                    title: "Error!",
                    message: `User Didn't Authorize!`,
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],
                    dismiss: {
                        duration: 3000,
                        onScreen: true,
                    },
                });
            }
        }, 0);
    };

    listenForSocket = () => {
        listenForServiceProviderResponse((err, res) => {
            // console.log("nikhil", res);
            this.setState({
                qrcode: false,
                response: false,
                showSpinner: true,
                showText: false,
            });
        });

        listenForUserData(async (err, res) => {
            let bytes = res.userDetails || res.newreq;
            console.log("bytes", bytes);
            console.log("testing-----", res.userDetails);
            console.log("testing-------", res.newreq);
            console.log("nikhil", res);
            this.setState({
                showQr: false,
                showSpinner: false,
            });
            let creditnals;
            let sessionKey;
            if (bytes === "denied" || bytes.permission === "denied") {
                this.userDenied();
            } else if (
                bytes.permission === "granted" ||
                bytes === "issuerLogin" ||
                bytes === "credentialsgenerated" ||
                bytes === "verifylogin" ||
                bytes === "success"
            ) {
                this.setState({
                    response: true,
                    showSpinner: false,
                    loggedIn: true,
                    showModal: false,
                    btnText: "LogOut",
                    bytes: "issuerLogin",
                });
                setTimeout(() => {
                    document.getElementById("exampleModalLogin").click();
                    let popMessage;
                    if (showNotification === true) {
                        if (this.state.bytes === "credentialsgenerated") {
                            popMessage = "Credentials Generated";
                        } else {
                            popMessage = `Login Successful`;
                        }
                        showNotification = false;
                        store.addNotification({
                            title: "Success!",
                            message: popMessage,
                            type: "success",
                            insert: "top",
                            container: "top-right",
                            animationIn: ["animated", "fadeIn"],
                            animationOut: ["animated", "fadeOut"],
                            dismiss: {
                                duration: 3000,
                                onScreen: true,
                            },
                        });
                        if (bytes.permission === "granted") {
                            this.updateNotificationState();
                        } else if (bytes === "verifylogin") {
                            this.verifyNotificationState();
                        }
                    }
                }, 0);
            } else if (bytes.userDetails.credentialType != null) {
                console.log("bytes", bytes);
                sessionKey = bytes.sessionKey;
                await getUserCreditnals(bytes.userDetails)
                    .then((data) => {
                        this.userCreditnalsSuccess(bytes);

                        creditnals = data;
                    })
                    .catch((err) => {
                        alert(err);
                    });
            } else {
                this.setState({
                    response: false,
                    showModal: false,
                });
                setTimeout(() => {
                    document.getElementById("exampleModalLogin").click();
                    if (showNotification === true) {
                        showNotification = false;
                        store.addNotification({
                            title: "Error!",
                            message: `User Didn't Authorize!`,
                            type: "danger",
                            insert: "top",
                            container: "top-right",
                            animationIn: ["animated", "fadeIn"],
                            animationOut: ["animated", "fadeOut"],
                            dismiss: {
                                duration: 3000,
                                onScreen: true,
                            },
                        });
                    }
                }, 0);
            }
        });
    };


    listenForSocket_cred = () => {
        listenForServiceProviderResponse((err, res) => {
            console.log("nikhil", res);
            this.setState({
                qrcode: false,
                response: false,
                showSpinner: true,
                showText: false,
            });
        });

        listenForUserData(async (err, res) => {
            let bytes = res.userDetails || res.newreq;
            console.log("bytes", bytes);

            if (bytes === "denied" || bytes.permission === "denied") {
                this.userDenied();
            } else if (
                bytes.permission === "granted" ||
                bytes === "issuerLogin" ||
                bytes === "credentialsgenerated" ||
                bytes === "verifylogin" ||
                bytes === "success"
            ) {
                this.setState({
                    response: true,
                    showSpinner: false,
                    loggedIn: true,
                    showModal: false,
                    btnText: "LogOut",
                    bytes: "credentialsgenerated"
                });
                Swal.fire({
                    title: 'Loading...',
                    showDenyButton: false,
                    showCancelButton: false,
                    confirmButtonText: `View Certificate`,
                    denyButtonText: `Copy URL`
                });

                Swal.showLoading();

                setTimeout(() => {
                    Swal.hideLoading();

                    Swal.fire({
                        title: 'How would you like to manage your certificate?',
                        showDenyButton: true,
                        showCancelButton: false,
                        confirmButtonText: `View Certificate`,
                        denyButtonText: `Copy Link`,
                        customClass: {
                            container: 'custom-popup-container',
                            title: 'custom-popup-title',
                            content: 'custom-popup-content',
                            confirmButton: 'custom-popup-confirm-button',
                            denyButton: 'custom-popup-deny-button',
                        }
                    }).then((result) => {
                        /* Read more about isConfirmed, isDenied below */
                        if (result.isConfirmed) {
                            // Download PDF file
                            window.open(`/certificate?bytes=${encodeURIComponent(JSON.stringify(bytes))}`, '_blank');
                        } else if (result.isDenied) {
                            // Copy URL to clipboard
                            const certificateURL = `https://credentials.myearth.id/certificate?bytes=${encodeURIComponent(JSON.stringify(bytes))}`;
                            //navigator.clipboard.writeText(certificateURL);
                            clipboardCopy(certificateURL)
                            .then(() => {
                              Swal.fire('Link Copied!', '', 'success');
                            })
                            .catch((error) => {
                              // Handle any error that occurred during copying
                              Swal.fire('Error!', 'Failed to copy the link.', 'error');
                            });
                        }
                    });
                }, 3000);

            };
        });
    };

    listenForSocket_cred_emp = () => {
        listenForServiceProviderResponse((err, res) => {
            console.log("nikhil", res);
            this.setState({
                qrcode: false,
                response: false,
                showSpinner: true,
                showText: false,
            });
        });

        listenForUserData(async (err, res) => {
            let bytes = res.userDetails || res.newreq;
            console.log("bytes", bytes);

            if (bytes === "denied" || bytes.permission === "denied") {
                this.userDenied();
            } else if (
                bytes.permission === "granted" ||
                bytes === "issuerLogin" ||
                bytes === "credentialsgenerated" ||
                bytes === "verifylogin" ||
                bytes === "success"
            ) {
                this.setState({
                    response: true,
                    showSpinner: false,
                    loggedIn: true,
                    showModal: false,
                    btnText: "LogOut",
                    bytes: "credentialsgenerated"
                });
                Swal.fire({
                    title: 'Loading...',
                    showDenyButton: false,
                    showCancelButton: false,
                    confirmButtonText: `View Certificate`,
                    denyButtonText: `Copy URL`
                });

                Swal.showLoading();

                setTimeout(() => {
                    Swal.hideLoading();

                    Swal.fire({
                        title: 'How would you like to manage your certificate?',
                        showDenyButton: true,
                        showCancelButton: false,
                        confirmButtonText: `View Certificate`,
                        denyButtonText: `Copy Link`,
                        customClass: {
                            container: 'custom-popup-container',
                            title: 'custom-popup-title',
                            content: 'custom-popup-content',
                            confirmButton: 'custom-popup-confirm-button',
                            denyButton: 'custom-popup-deny-button',
                        }
                    }).then((result) => {
                        /* Read more about isConfirmed, isDenied below */
                        if (result.isConfirmed) {
                            // Download PDF file
                            window.open(`/employment-certificate?bytes=${encodeURIComponent(JSON.stringify(bytes))}`, '_blank');
                        } else if (result.isDenied) {
                            // Copy URL to clipboard
                            const certificateURL = `https://credentials.myearth.id/employment-certificate?bytes=${encodeURIComponent(JSON.stringify(bytes))}`;
                            //navigator.clipboard.writeText(certificateURL);
                            clipboardCopy(certificateURL)
    .then(() => {
      Swal.fire('Link Copied!', '', 'success');
    })
    .catch((error) => {
      // Handle any error that occurred during copying
      Swal.fire('Error!', 'Failed to copy the link.', 'error');
    });
                        }
                    });
                }, 3000);

            };
        });
    };

    userCreditnalsSuccess = (data) => {
        if (data.userDetails.credentialType != null) {
            this.setState({
                response: true,
                showSpinner: false,
                loggedIn: true,
                showModal: false,
                btnText: "LogOut",
            });
            setTimeout(() => {
                document.getElementById("exampleModalCred").click();
                let popMessage;
                if (showNotification === true) {
                    popMessage = "Credentials Generated";
                    showNotification = false;
                    store.addNotification({
                        title: "Success!",
                        message: popMessage,
                        type: "success",
                        insert: "top",
                        container: "top-right",
                        animationIn: ["animated", "fadeIn"],
                        animationOut: ["animated", "fadeOut"],
                        dismiss: {
                            duration: 3000,
                            onScreen: true,
                        },
                    });
                } else {
                    this.setState({
                        response: false,
                        showModal: false,
                    });
                    setTimeout(() => {
                        document.getElementById("exampleModalCred").click();
                        if (showNotification === true) {
                            showNotification = false;
                            store.addNotification({
                                title: "Error!",
                                message: `User Didn't Authorize!`,
                                type: "danger",
                                insert: "top",
                                container: "top-right",
                                animationIn: ["animated", "fadeIn"],
                                animationOut: ["animated", "fadeOut"],
                                dismiss: {
                                    duration: 3000,
                                    onScreen: true,
                                },
                            });
                        }
                    }, 0);
                }
            }, 0);
        }
    };

    generateQrCodeData = async () => {
  
        registerVendor()
            .then((d) => {
                const timestamp = Date.now();
                const hash = crypto
                    .createHmac("sha256", d.secretKey)
                    .update(JSON.stringify(d.apiKey + "Rejolut@1008" + timestamp))
                    .digest("hex");
                generateQrCodeNest(hash, d.apiKey, "Rejolut@1008", timestamp, "login")
                    .then((data) => {
                        this.listenForSocket();
                        this.setState({
                            showQr: true,
                            qrCodeData: data,
                            apiKey: this.state.apikey,
                            qrcode: false,
                        });
                    })
                    .catch((err) => {
                        alert(err);
                    });
            })
            .catch((err) => {
                alert(err);
            });
    };

    generateCredQrCodeData = () => {
        registerVendor()
            .then((d) => {
                const timestamp = Date.now();
                const hash = crypto
                    .createHmac("sha256", d.secretKey)
                    .update(JSON.stringify(d.apiKey + "Rejolut@1008" + timestamp))
                    .digest("hex");
                generateQrCodeNest(
                    hash,
                    d.apiKey,
                    "Rejolut@1008",
                    timestamp,
                    "generateCredentials"
                )
                    .then((data) => {
                        this.listenForSocket_cred();
                        this.setState({
                            showQr: true,
                            qrCodeData: data,
                            apiKey: this.state.apikey,
                            qrcode: false,
                        });
                        console.log("test");
                    })
                    .catch((err) => {
                        alert(err);
                    });
            })
            .catch((err) => {
                alert(err);
            });
    };

    generateCredQrCodeDataEmp = () => {
        registerVendor()
            .then((d) => {
                const timestamp = Date.now();
                const hash = crypto
                    .createHmac("sha256", d.secretKey)
                    .update(JSON.stringify(d.apiKey + "Rejolut@1008" + timestamp))
                    .digest("hex");
                generateQrCodeNest(
                    hash,
                    d.apiKey,
                    "Rejolut@1008",
                    timestamp,
                    "generateCredentials"
                )
                    .then((data) => {
                        this.listenForSocket_cred_emp();
                        this.setState({
                            showQr: true,
                            qrCodeData: data,
                            apiKey: this.state.apikey,
                            qrcode: false,
                        });
                        console.log("test");
                    })
                    .catch((err) => {
                        alert(err);
                    });
            })
            .catch((err) => {
                alert(err);
            });
    };



    qrcode = () => {
        let { qrCodeData } = this.state;
        return (
            <div className="qr-container">
                <img src={qrCodeData} alt="new" />
            </div>
        );
    };

    redirectToHome() {
        window.location.href = "/";
    }
    redirectToGenerateCred() {
        window.location.href = "/generateCred";
    }

    redirectToVerifyCreditnals() {
        window.location.href = "/verifyCredentials";
    }

 


    render() {
        let showButton = this.state.showSpinner ? (
            <div className="spinner-div">
                <img className="spinner" src={require("./spinner.gif")} />
            </div>
        ) : null;
        return (
            <div className="App">

                <nav className="navbar navbar-expand-lg navbar-light">
                    <div
                        className=" collapse navbar-collapse pull-right "
                        id="navbarTogglerDemo01"
                    >
                        <div className="clickable" onClick={() => this.redirectToHome()}>
                            <img src={require("./EarthID Logo - Primary - BLACK.png")} alt="logo" className="logo" width="160" height="50" />{" "}
                         
                        </div>
                        <ul className=" navbar-nav mr-auto mt-2 mt-lg-0" style={{
                            marginLeft: "1.5rem", textTransform: "uppercase"
                            , color: "White"
                        }}>
                            <li class="nav-item">
                                <a class="nav-link active" aria-current="page" href="#">Home</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" href="#">About</a>
                            </li>
                        </ul>

                        {localStorage.user_name ? (
                            <div>
                                <label className="welcomeLabel">{`Welcome, ${localStorage.getItem(
                                    "user_name"
                                )}`}</label>
                            </div>
                        ) : null}

                        <div className="profileAccess">



                            {window.location.pathname == "/" ? (
                                <div>
                                    <button
                                        type="button"
                                        className="nav-buttons"
                                        data-toggle="modal"
                                        data-target="#exampleModalLogin"
                                        onClick={this.state.btnText == "LogOut" ? this.redirectToHome : this.generateQrCodeData}
                                        style={{ marginRight: "2rem", marginLeft: "2rem" }}
                                    >
                                        {this.state.btnText}
                                    </button>
                                </div>
                            ) : window.location.pathname == "/generateCred" ? (
                                <div className="d-flex">

                                    <div className="dropdown">
                                        <button
                                            className="nav-buttons dropdown-toggle"
                                            type="button"
                                            id="dropdownMenuButton"
                                            data-toggle="dropdown"
                                            aria-haspopup="true"
                                            aria-expanded="false"
                                        >
                                            Request Credentials
                                        </button>
                                        <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">

                                            <a
                                                className="dropdown-item"
                                                data-toggle="modal"
                                                data-target="#exampleModalCred"
                                                onClick={this.generateCredQrCodeDataEmp}
                                                style={{ textAlign: "center", cursor: "pointer" }}
                                            >
                                                Employment Certificate
                                            </a>
                                            <a
                                                className="dropdown-item"
                                                data-toggle="modal"
                                                data-target="#exampleModalCred"
                                                onClick={this.generateCredQrCodeData}
                                                style={{ textAlign: "center", cursor: "pointer" }}
                                            >
                                                Project Certificate
                                            </a>
                                        </div>
                                    </div>
                                    <button
                                        type="button"
                                        className="nav-buttons"
                                        data-toggle="modal"
                                        data-target="#exampleModal"
                                        onClick={this.redirectToHome}
                                    >
                                        Logout
                                    </button>
                                </div>
                            ) : (
                                <div>Not found</div>
                            )}
                        </div>
                    </div>
                </nav>

              
<Baseui/>

                <div className="modal_container" id="modal-container">
                    <div className="modal">
                        <div className="qr-code">
                            <img
                                src='https://api.qrserver.com/v1/create-qr-code/?data={"apikey":"UKURGTREFATPSYJ","reqNo":"BAZQUVRDTLF","sessionKey":"BAZQUVRDTLF","requestType":"login","encryptionkey":"loginDetailsEncryption" }'
                                alt="QR-Code"
                            />
                        </div>

                        <button id="btn-close">Close me</button>
                    </div>
                </div>

                <div className="hero-section">
                    <div className="shoppingCart"></div>
                    <div
                        className="modal fade"
                        id="exampleModalLogin"
                        tabIndex="-1"
                        role="dialog"
                        aria-labelledby="exampleModalLabel"
                        aria-hidden="true"
                    >
                        <div className="modal-dialog" role="document">
                            {
                                <div>
                                    {this.state.showModal ? (
                                        <div className="modal-content">
                                            <div className="modal-header">
                                                <h4 style={{ color: "black" }}>
                                                    {this.state.modalHeading && !this.state.showSpinner
                                                        ? "Use Earth ID app to scan the QR code"
                                                        : "Waiting for user authorization"}
                                                </h4>
                                            </div>
                                            <div className="modal-body">
                                                <div className="pop-upbox">
                                                    {showButton}
                                                    {this.state.response
                                                        ? null
                                                        : this.state.qrcode
                                                            ? this.generateQrCodeData()
                                                            : null}
                                                    {this.state.showQr ? (
                                                        this.qrcode()
                                                    ) : this.state.showSpinner ? (
                                                        <img
                                                            className="spinner"
                                                            src={require("./spinner.gif")}
                                                        />
                                                    ) : null}
                                                </div>
                                            </div>
                                            <div className="modal-footer">
                                                <button
                                                    type="button"
                                                    className="close-button"
                                                    data-dismiss="modal"
                                                >
                                                    Close
                                                </button>
                                            </div>
                                        </div>
                                    ) : null}
                                </div>
                            }
                        </div>
                    </div>
                </div>

                <div className="hero-section">
                    <div className="shoppingCart"></div>
                    <div
                        className="modal fade"
                        id="exampleModalCred"
                        tabIndex="-1"
                        role="dialog"
                        aria-labelledby="exampleModalLabel"
                        aria-hidden="true"
                    >
                        <div className="modal-dialog" role="document">
                            {
                                <div>
                                    {this.state.showModal ? (
                                        <div className="modal-content">
                                            <div className="modal-header">
                                                <h4>
                                                    {this.state.modalHeading && !this.state.showSpinner
                                                        ? "Use Earth ID app to scan the QR code"
                                                        : "Waiting for user authorization"}
                                                </h4>
                                            </div>
                                            <div className="modal-body">
                                                <div className="pop-upbox">
                                                    {showButton}
                                                    {this.state.response
                                                        ? null
                                                        : this.state.qrcode
                                                            ? this.generateCredQrCodeData()
                                                            : null}
                                                    {this.state.showQr ? (
                                                        this.qrcode()
                                                    ) : this.state.showSpinner ? (
                                                        <img
                                                            className="spinner"
                                                            src={require("./spinner.gif")}
                                                        />
                                                    ) : null}
                                                </div>
                                            </div>
                                            <div className="modal-footer">
                                                <button
                                                    type="button"
                                                    className="btn btn-secondary p-btn"
                                                    data-dismiss="modal"
                                                >
                                                    Close
                                                </button>
                                            </div>
                                        </div>
                                    ) : null}
                                </div>
                            }
                        </div>
                    </div>
                </div>


            </div>
        );
    }
}

export default CreateCred;
