import React, { useState, useEffect } from 'react';
import './serviceNow.scss';
import logo from '../../img/kpmg-logo-1.jpg'
import favicon from '../../img/EarthID FAVICON - PRIMARY.png'
import check from '../../img/check.png'
import ssiApi from '../../ssiApi.js';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

// const vcString = localStorage.getItem("verifiableCredential");
// const vcArray = JSON.parse(vcString);

// const lastElement = vcArray[vcArray.length - 1];
// const lastElement = {"issuanceDate": "2023-05-23T07:51:31.832Z", "expirationDate": "2024-05-23T07:51:29.991Z"};
//   console.log(lastElement);
const vcString = localStorage.getItem("verifiableCredential");

    if (vcString === null) {
      const newVCObject = {
        CreationDate: "D:20230414125909+04'00'",
        IsAcroFormPresent: false,
        IsCollectionPresent: false,
        IsLinearized: false,
        IsSignaturesPresent: false,
        IsXFAPresent: false,
        ModDate: "D:20230414125909+04'00'",
        PDFFormatVersion: "1.4",
        Producer: "mPDF 8.0.3",
        formattedDateTime: "2023-04-14 12:59:09",
        uniqueId: "idh91sjmi"
      };

      localStorage.setItem("verifiableCredential", JSON.stringify(newVCObject));
    }

    const vcArray = JSON.parse(localStorage.getItem("verifiableCredential"));
    const lastElement = vcArray[vcArray.length - 1];
    console.log(lastElement);

const ServiceNow = () => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isVerificationLoading, setIsVerificationLoading] = useState([true, true, true, true, true]);
  const [verificationData, setVerificationData] = useState([]);
  const [isButtonClicked, setIsButtonClicked] = useState(false);
  const [isVerifying, setIsVerifying] = useState(false);




  useEffect(() => {
    // Simulating data fetching delay
    //const delay = 5000;

    if (isButtonClicked) {
      setIsLoading(true);
      setIsVerificationLoading([true, true, true, true, true]);
      setVerificationData([]);

      setTimeout(() => {
        setIsLoading(false);
        setIsVerificationLoading([true, true, true, true, true]);
        setVerificationData([lastElement.issuanceDate.toLocaleString('en-US', {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric',
          }), "KPMG", "ServiceNow"]);
        setTimeout(() => {
          setIsVerificationLoading([false, true, true, true, true]);
          setVerificationData([lastElement.issuanceDate.toLocaleString('en-US', {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric',
          }), "KPMG", "ServiceNow"]);
          setTimeout(() => {
            setIsVerificationLoading([false, false, true, true, true]);
            setVerificationData([lastElement.issuanceDate.toLocaleString('en-US', {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
          second: 'numeric',
        }), "KPMG", "ServiceNow"]);
            setTimeout(() => {
              setIsVerificationLoading([false, false, false, true, true]);
              setVerificationData([lastElement.issuanceDate.toLocaleString('en-US', {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
          second: 'numeric',
        }), "KPMG", "ServiceNow"]);
              setTimeout(() => {
                setIsVerificationLoading([false, false, false, false, true]);
                setVerificationData([lastElement.issuanceDate.toLocaleString('en-US', {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
          second: 'numeric',
        }), "KPMG", "ServiceNow"]);
                setTimeout(() => {
                  setIsVerificationLoading([false, false, false, false, false]);
                  setVerificationData([lastElement.issuanceDate.toLocaleString('en-US', {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
          second: 'numeric',
        }), "KPMG", "ServiceNow"]);
            }, 1000);
          }, 1000);
        }, 1000);
      }, 1000);
    }, 1000);
}, 5000);
    }

    setIsButtonClicked(false);
  }, [isButtonClicked]);

  const handlePopupOpen = () => {
    setIsPopupOpen(true);
  setIsVerifying(true);
  setIsButtonClicked(true);

  // Call verifyVC() here
ssiApi.verifyVC(lastElement).then((verificationResult) => {
    // Process the verification result and update state variables accordingly
    setIsVerifying(false);
    // Update other state variables based on verification result
    // Example:
    // setIsVerificationLoading([...]);
    // setVerificationData([...]);
  }).catch((error) => {
    // Handle error
    setIsVerifying(false);
    // Display error message or take appropriate action
  });
  };

  const handlePopupClose = () => {
    setIsPopupOpen(false);
  };

  const handleViewCertificate = () => {
    const MySwal = withReactContent(Swal);
  
    MySwal.fire({
      title: 'Digital Certificate(VC)',
      html: <pre>{JSON.stringify(lastElement, null, 2)}</pre>,
      confirmButtonText: 'Close',
      customClass: {
        content: 'certificate-swal',
      },
    });
  };

  return (
    <div className="page-container">
      <nav className="navbar">
        <div className="logo">
          <img src={logo}></img>
        </div>
        <ul className="nav-menu">
          <li className="nav-item"><a href="/certificate/EarthID">Home</a></li>
        </ul>
      </nav>
      <div className="certificate-container">
        <section className='certBack'>
          <div className="certificate">
            <div className="bodbox">

              <div className="header">
                <div className="favico">
                  <img src={logo}></img>
                </div>
                <div className="title">Certificate of Audit</div>
              </div>
              <div className="title1">Year 2023-2024</div>
              <div className="title1">This document verifies that</div>

              <div className="body">
                <div className="name">ServiceNow</div>
                <div className="title1">has succesfully gone through the audit and <br></br>has completed all requirements for the necessary industry standards.<br></br>This certificate is a verifiable credential and <br></br>
                is valid till: {lastElement.expirationDate.toLocaleString('en-US', {
          year: 'numeric',
          month: 'long',
          day: 'numeric'
        })}.
                </div>
              </div>
              <div>
                <div className='signature'>KPMG</div>
                {/* <div className='signature1'>KPMG</div> */}
              </div>


            </div>
          </div>
        </section>
      </div>
      <section className='infosec'>
        <div className='container'>
          <div className='row'>
            <div className='leftsec col-md-8'>
              <div className='row'>
                <div className="check col-md-1">
                  <img src={check}></img>
                </div>
                <h2 className='coursename col-md-11'>Certificate of Audit</h2>
              </div>

              <div className='stuname'>Issued to: ServiceNow</div>
              <div className='stuID'>ID: EarthID</div>
              <hr></hr>
              <div className='description'>
              We are pleased to announce that ServiceNow has been awarded a successful audit certificate, recognizing their commitment to excellence and adherence to industry standards. This achievement reflects ServiceNow's dedication to maintaining high levels of transparency, accountability, and compliance in their operations. The certificate serves as a testament to ServiceNow's 
              exceptional performance and positions them as a trusted and reliable organization in their field.
              </div>
              <hr></hr>
              <div className='row'>
                <div className='issueon col-md-6'>
                  <div>Issued On</div>
                  <div>{lastElement.issuanceDate.toLocaleString('en-US', {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
          second: 'numeric',
        })}</div>
                </div>
                <div className='expiry col-md-6'>
                  <div>Expires On</div>
                  <div>{lastElement.expirationDate.toLocaleString('en-US', {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
          second: 'numeric',
        })}</div>
                </div>
              </div>
              <button className='verify-btnn' onClick={handleViewCertificate}>View Digital Certificate</button>
            </div>
            <div className='col-md-4'>
              <div className="footer1">
                <div className='verify'>Credential Verification</div>
                <div className='verify1'>This credential is from a verified issuer</div>
                <button className="verify-btn" onClick={handlePopupOpen}>
                  Verify
                </button>
              </div>
              <div className="footer1">
                <div className='verify'>More about the Issuer</div>
                <div className='verify1'>KPMG</div>
                <button className="verify-btn" onClick={() => window.open('https://bank.myearth.id', '_blank', 'noopener')} rel="noopener">
                  Visit Issuer Website
                </button>
              </div>
            </div>

          </div>

        </div>

      </section>

      {isPopupOpen && (
        <div className="popup-container">
          {isVerifying ? (
            <div>
              <div className="spinner"></div>
              <div className="spinner-text">Verifying Credentials...</div>
            </div>
          ) : (
            <div className="popup">
              <div className="popup-header">
                <div className="popup-title">Verification Details</div>
                <button className="popup-close" onClick={handlePopupClose}>
                  X
                </button>
              </div>
              <hr></hr>
              <div className="popup-body">
                {isVerificationLoading[0] ? (
                  <div>
                    <div className="verification-item">
                      <div className="verification-result">
                        <div className="spinner1"></div>
                      </div>
                      <div className="verification-label">Issuer is Valid:</div>
                    </div>
                    <hr></hr>
                  </div>

                ) : (
                  <div>
                    <div className="verification-item">
                      <div className="verification-result">
                        <div className="success">&#10004;</div>
                      </div>
                      <div className="verification-label">Issuer is Valid:</div>

                      <div className="verification-result1">
                        <div>The issuer is found to be valid</div>
                      </div>
                    </div>
                    <hr></hr>
                  </div>

                )}
                {isVerificationLoading[1] ? (
                  <div>
                    <div className="verification-item">
                      <div className="verification-result">
                        <div className="spinner1"></div>
                      </div>
                      <div className="verification-label">Issued on:</div>
                    </div>
                    <hr></hr>
                  </div>

                ) : (
                  <div>
                    <div className="verification-item">
                      <div className="verification-result">
                        <div className="success">&#10004;</div>
                      </div>
                      <div className="verification-label">Issued on:</div>

                      <div className="verification-result1">
                        {verificationData[0] && <div>{verificationData[0]}</div>}
                      </div>
                    </div>
                    <hr></hr>
                  </div>

                )}
                {isVerificationLoading[2] ? (
                  <div>
                    <div className="verification-item">
                      <div className="verification-result">
                        <div className="spinner1"></div>
                      </div>
                      <div className="verification-label">Issued by:</div>
                    </div>
                    <hr></hr>
                  </div>

                ) : (
                  <div>
                    <div className="verification-item">
                      <div className="verification-result">
                        <div className="success">&#10004;</div>
                      </div>
                      <div className="verification-label">Issued by:</div>

                      <div className="verification-result1">
                        {verificationData[1] && <div>{verificationData[1]}</div>}
                      </div>
                    </div>
                    <hr></hr>
                  </div>

                )}
                {isVerificationLoading[3] ? (
                  <div>
                    <div className="verification-item">
                      <div className="verification-result">
                        <div className="spinner1"></div>
                      </div>
                      <div className="verification-label">Issued to:</div>
                    </div>
                    <hr></hr>
                  </div>

                ) : (
                  <div>
                    <div className="verification-item">
                      <div className="verification-result">
                        <div className="success">&#10004;</div>
                      </div>
                      <div className="verification-label">Issued to:</div>

                      <div className="verification-result1">
                        {verificationData[2] && <div>{verificationData[2]}</div>}
                      </div>
                    </div>
                    <hr></hr>
                  </div>

                )}
                {isVerificationLoading[4] ? (
                  <div>
                    <div className="verification-item">
                      <div className="verification-result">
                        <div className="spinner1"></div>
                      </div>
                      <div className="verification-label"></div>
                    </div>
                    <hr></hr>
                  </div>

                ) : (
                  <div>
                    <div className="verification-item">
                      <div className="verification-result">
                        <div className="success1"><img src={check}></img></div>
                      </div>
                      <div className="verification-label"></div>

                      <div className="verification-result1">
                        <div className='verified'>VERIFIED</div>
                      </div>
                    </div>
                    <hr></hr>
                  </div>

                )}
              </div>
            </div>
          )}
        </div>
      )}
      <footer className="footer">
        <div className="logo"></div>
        <div className="footer-text">
          © 2023 EarthID, Inc. All Rights Reserved.
        </div>
      </footer>
    </div>
  );
};

export default ServiceNow;
